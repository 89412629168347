@import "./src/styles/mixins";
@import "./src/styles/fonts";

.Card_Need {
  padding: 10px 0;
  //margin-right: 32px;
  //@include UiMobile() {
  //  margin-right: 8px;
  //}
}

.DonorNeeds {
  &_Title {
    & > span {

    }
  }

  &_Content {
  }
}

.DonorNeedCard {
  border-radius: 8px;
  max-width: 304px;
  min-width: 304px;
  transition: .2s ease-in-out all;
  cursor: pointer;
  box-shadow: 0 4px 16px rgba(37, 36, 36, 0.05);

  &:hover {
    box-shadow: 0 4px 24px rgba(37, 36, 36, 0.1);
  }

  &_Container {
    display: flex;
    flex-direction: column;
    background-color: $White;
    border-radius: 8px;

    &_Header {
      display: flex;
      flex-direction: column;
      border-radius: 8px 8px 0 0;
      background: url("/static/images/DonorNeedCardBack.svg") no-repeat;
      background-size: cover;
      background-position-x: center;
      background-position-y: bottom;
      padding: 16px 16px 38px 16px;

      &_Top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &_Left {
          margin: 4px 0;

          & > img {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }

          & > span {
            @extend .Text_text_4;
            color: $White;
          }
        }

        &_Right {
          & > span {
            margin: 4px 0;
            @extend .Text_text_4;
            color: $White;
          }
        }
      }

      &_Mid {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 16px;
        padding: 4px 0;
        gap: 4px;
      }

      &_Bot {
        margin-top: 16px;

        & > span {
          @extend .StaticTitle_3;
          color: $White;
          margin: 8px 0;
        }
      }
    }

    &_Body {
      padding: 0 16px 16px 16px;
      background-color: $White;
      display: flex;
      flex-direction: column;
      border-radius: 0 0 8px 8px;

      &_Address {
        display: flex;
        margin: 12px 0 8px;
        padding: 4px 0;

        &_Image {
          width: 24px;
          height: 32px;
          margin-right: 8px;

          & > svg {
            margin: 4px 0;
          }
        }

        &_Text {
          margin: 4px 0;

          & > span {
            @extend .Text_text_4;
            color: $BaG_gray_one;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
          }
        }
      }

      &_Users {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 12px 0;
        height: 40px;

        &_Count {
          margin: 4px 0;

          & > span {
            @extend .Text_text_4;
            color: $BaG_gray_one;
          }
        }

        &_Images {
          display: flex;
          align-items: center;

          & > div {
            margin-left: -12px;
          }
        }
      }

      &_Author {
        display: flex;
        flex-direction: column;

        &_Header {
          margin-top: 12px;

          & > span {
            @extend .Caption_1;
            margin: 4px 0;
            color: $BaG_gray_three;
          }
        }

        &_Body {
          margin-top: 4px;
          padding: 12px 0;
          display: flex;
          align-items: center;

          &_Image {
            margin-right: 16px;

            & > img {
              width: 48px;
              height: 48px;
              border-radius: 50%;
            }
          }

          &_Text {
            & > span {
              @extend .Text_text_4;
              color: $BaG_gray_one;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}

.DonorNeedCardNeed {
  background: $White;
  box-shadow: 0 4px 20px rgba(19, 20, 22, 0.08);
  border-radius: 12px;
  display: flex;
  align-items: center;

  & > span {
    @extend .Lead_4;
    margin: 8px 16px;
    font-weight: 500;
    color: $BaG_gray_two;
  }
}
