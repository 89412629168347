@import "./src/styles/mixins";
@import "./src/styles/fonts";

.TopDonor {
  &_Block {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 104px;
  }

  &_Title {
    font-weight: 800;
    font-size: 36px;
    line-height: 44px;
    color: $BaG_gray_one;
    display: block;
    margin-bottom: 48px;

    @include beMobile() {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 20px;
    }
  }

  &_WithAdvice {
    padding: 24px;
    background: $White;
    border-radius: 20px;
    display: flex;
    flex-direction: column;

    &_Advice {
      position: relative;
      transition: .2s ease-in-out all;
      margin-top: 16px;

      &_Text_Closed {
        overflow: hidden;
        @extend .Text_text_3;
        color: $BaG_gray_one;
        transition: .2s ease-in-out all;
        max-height: 150px;
      }

      &_Text_Opened {
        overflow: hidden;
        @extend .Text_text_3;
        color: $BaG_gray_one;
        transition: .2s ease-in-out all;
        max-height: 1000px;
      }

      &_Overflow {
        display: flex;
        flex-direction: column;
        transition: .2s ease-in-out all;

        &_Smooth {
          background: linear-gradient(0deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 115%);
          height: 70px;
          position: absolute;
          width: 100%;
          bottom: 0;
          z-index: 0;
        }

        &_Button {
          width: 100%;
          margin-bottom: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          @extend .TTitle_button_medium;
          cursor: pointer;
          z-index: 1;

          &_Arrow {
            transition: .2s ease-in-out all;
            margin-left: 8px;

            &[data-reversed="true"] {
              transform: rotate(-180deg);
            }
          }
        }
      }
    }

    &_Container {
      width: 100%;
      position: relative;

      &_User {
        width: 100%;
        display: flex;
        flex-direction: row;
        min-height: 98px;
        @include UiMobile() {
          flex-direction: column;
          min-height: 182px;
        }
        @include UiTablet() {
          flex-direction: column;
          min-height: 182px;
        }

        &_Content {
          display: flex;
          align-items: center;

          &_Info {
            margin-right: 12px;
          }

          &_Data {
            &_Name {
              @extend .Lead_2;
              color: $BaG_black;
              margin-bottom: 4px;
            }

            &_City {
              @extend .Text_text_3;
              color: $BaG_gray_two;
            }
          }
        }

        &_Info {
          display: flex;
          flex-direction: column;
          height: 100%;
          margin-left: auto;
          @include UiMobile() {
            margin-left: 0;
          }

          @include UiTablet() {
            margin-left: 0;
          }

          &_Donations {
            margin-top: 4px;
            @extend .Caption_2;
            color: $BaG_gray_five;

            & > span {
              @extend .Caption_1;
            }
          }

          &_BloodTypes {
            display: flex;
            margin-top: 8px;
          }
        }
      }

      &_Advice {
        margin-top: 8px;
        position: relative;
      }
    }
  }

  &_DefaultCard {
    padding: 16px;
    max-height: 257px;
    max-width: 257px;
    background: $White;
    border-radius: 20px;
    display: flex;
    flex-direction: column;

    &[data-me='true'] {
      border: 1px solid $Red_one;
    }

    //@media (min-width: 380px) and (max-width: 750px) {
    //  flex-direction: row !important;
    //  width: 100% !important;
    //}

    //@include laptop() {
    //  flex-direction: row;
    //  width: 100%;
    //}

    &_Content {
      display: flex;
      align-items: center;

      &_Info {
        margin-right: 12px;
      }

      &_Data {
        &_Name {
          @extend .Lead_2;
          color: $BaG_black;
          margin-bottom: 4px;
        }

        &_City {
          @extend .Text_text_3;
          color: $BaG_gray_two;
        }
      }
    }

    &_Info {
      display: flex;
      flex-direction: column;
      height: 100%;

      //@media (min-width: 380px) and (max-width: 750px) {
      //  margin-left: auto;
      //}
      //
      //@include laptop() {
      //  margin-left: auto !important;
      //}

      &_Donations {
        margin-top: 4px;
        @extend .Caption_2;
        color: $BaG_gray_five;

        & > span {
          @extend .Caption_1;
        }
      }

      &_BloodTypes {
        display: flex;
        margin-top: 8px;
      }
    }
  }

  &_BloodType {
    margin-right: 12px;
  }
}


.TeamCard {
  background-color: $White;
  min-height: 204px;
  border-radius: 8px;
  border: 1px solid $BorderColor;

  &_Container {
    padding: 24px;
    display: flex;
    flex-direction: column;

    &_Top {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      &_Image {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        width: 60px;
        height: 60px;

        & > img {
          border-radius: 50%;
          margin: 4px 0;
          width: 100%;
          height: auto;
          aspect-ratio: 1;
        }
      }

      &_Name {
        display: flex;
        align-items: center;
        height: 60px;

        & > span {
          @extend .StaticTitle_3;
          color: $BaG_black;
          margin: 4px 0;
        }
      }
    }

    &_Bot {
      margin: 4px 0;

      & > span {
        @extend .Lead_3;
        font-weight: normal;
        color: $BaG_black;
      }
    }
  }
}
