@import "src/styles/mixins";
@import "src/styles/shortcuts";
@import "src/styles/fonts";

.Event {
  min-height: 225px;
  box-shadow: 0px 4px 16px rgba(37, 36, 36, 0.05);
  border-radius: 8px;
  cursor: pointer;
  background: #ffffff;
  // margin: 0 5% 0 0;
  height: 460px;
  padding: 24px 16px;
  display: block;
}

.EventCard {
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__logoAndName {
      display: flex;
      align-items: center;
    }
  &__logoAndName > div {
      padding: 0 60px 0 0;
      width: 60px;
      height: 60px;
  }  
  &__name {
      margin: 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      margin: 0 0 0 15px;
    }
    &__logo {
      width: 60px;
      height: 60px;
      margin: 0;
    }
    &__title {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      margin: 0;
      text-align: start;
      padding: 0;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;

    }
    &__partners {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: start;
      color: #b5b7ba;
      margin: 0;
    }
    &__partnersText {
      margin: 0;
    }
    &__address {
      color: #333333;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: start;
      padding: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    &__head {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #dee0e3;
      padding: 0 0 8px;
      align-items: center;
      text-align: center;
      min-height: 57px;
    }
}


.HeadDate {
  margin: 0;
  text-align: start;
  &__number {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #131416;
    }
    &__month {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #4e5355;
    }
    &__images {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(12%, max-content));
      column-gap: 5px;
      row-gap: 5px;
      justify-content: space-between;
    }
    &__images > div {
      max-width: 32px !important;
      width: 32px !important;
    }
  //   &__images > img {
  //     margin-right: 5px;
  //   }
    &__img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
    &__img:last-of-type {
      margin-left: 5px;
    }
}

.HeadOneDate {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
      
.EventCardFooterButton {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  background: #f73232;
  border-radius: 4px;
  padding: 14px 0;
  width: 100%;
  color: #ffffff;
  border: 0;
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
}

.EventCardFooterButtonGray {
  background: #dee0e3;
}

.EventCardFooterButtonGrayColor {
  color: #131416;
}

.EventCardFooterButton:hover {
  opacity: 0.9;
}

.EventCardFooterButtonMargin {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  background: #f73232;
  border-radius: 4px;
  padding: 14px 0;
  width: 100%;
  color: #ffffff;
  border: 0;
  cursor: pointer;
  margin-bottom: 42px;
  width: 100%;
}

.VolunteersBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -7px;
  height: 40px;
  &__text {
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.15px;
      color: #b5b7ba;
      max-width: 75%;
      margin: 0;
    }
  &_hidden {
      display: none;
  }
  &__avatar {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      margin-left: -15px;
      border: 1px solid #ffffff;
      background-color: #ffffff;
  }
}

.VolunteersAvatars {
  display: flex;
  margin-top: 8px;
}

.VolunteersAvatars > div {
  margin-left: -15px !important;
  min-width: 32px !important;
}

.TopContainer {
  display: flex;
  margin-right: 32px;
  align-items: center;
}

.TopContainerFirst {
  background: #f4f5f6;
  border-radius: 5px;
  padding: 10px 8px;
  width: fit-content;
}


.TopContainerImg {
  margin-right: 5px;
  width: 15px;
  height: 14px;
}

.TopContainerText {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.15px;
  color: #4e5355;
  text-align: center;
}

.EmptyAvatar {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 32px;
}

.AvatarBlackNumbers {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  top: calc(50% - 20px / 2);
  left: calc(50% - 18px / 2);
  color: #131416;
}

.Faces {
  display: flex;
  align-items: center;

  & > div {
    margin-left: -12px;
    flex: 1;
    height: 100%;
  }
}

.LastUserCount {
  background-color: $White;
  display: flex;
  border-radius: 50%;
  border: 1px solid $White;

  &[data-black='true'] {
    background-color: $BaG_gray_one;
    border: 1px solid $BaG_gray_one;

    & > span {
      color: $White;
    }
  }

  & > span {
    @extend .TTitle_lvl5;
    text-align: center;
    flex: 1;
    align-self: center;
  }
}


@media (min-width: 320px) and (max-width: 1199px) {
  .EventCard {
      &__title {
          -webkit-line-clamp: 1;
      }
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .Event {
    margin: 0;
  }
}
