@import "mixins";

.Video {
  border-radius: 8px;
  width: 100%;
  height: 352px;
  @include beMobile() {
    height: 185px;
  }
}

.element {
  margin-right: 5px;
  margin-left: 5px;
}

.HomeMainBlockContainer {
  padding-bottom: 40px;

  & > div:nth-child(2) {
    margin-top: 64px;
    @include beMobile() {
      margin-top: 80px;
    }
  }
}


.HomeMainBlockContainerWM {
  padding-bottom: 0;

  & > div:nth-child(2) {
    margin-top: 64px;
  }
}


@media (max-width: 1200px) {
  .HomeMainBlockContainerWM {
    & > div:nth-child(2) {
      margin-top: 48px;
    }
  }
}

.mainDonationCards {
  margin-left: 5px;
  margin-right: 5px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  & > a {
    text-decoration: none;
    color: inherit;
  }
}

.Card {
  &_Event {
    padding: 10px 0;
    // margin-right: 32px;
    margin-right: 15px;
    @include UiMobile() {
      margin-right: 8px;
    }
    @include UiTablet() {
      margin-right: 16px;
    }
  }

  &_Bonus {
    padding: 10px 0;
    margin-right: 32px;
    @include UiMobile() {
      margin-right: 8px;
    }
  }
}

.swiper {
  &_element {
    padding: 10px 0;

    &:first-child {
      margin-left: 0;
    }

    @include beMobile() {
      margin-right: 5px !important;
      margin-left: 5px !important;
    }

    @extend .element;
  }

  & > ul {
    &:first-child {
      margin-bottom: auto;
    }
  }

  &_customElement {
    @include beMobile() {
      margin-right: 16px !important;
    }

    margin-right: 16px;
  }
}

.MoscowAdditionalText {
  max-width: 1280px;
  margin-top: 68px;
  padding: 32px 0;

  & > span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: #5F6177;
  }
}

@media (max-width: 768px) {
  .HomeMainBlockContainer {
    padding-bottom: 50px;

    & > div:nth-child(2) {
      margin-top: 48px;
    }
  }
  .MoscowAdditionalText {
    margin-top: 68px;
    padding: 0 0 16px 0;
  }

  .mainDonationCards {
    display: grid;
    justify-content: space-between;
    grid-template: none;
    grid-template-rows: repeat(3, 1fr);
    gap: 20px;

    & > a {
      text-decoration: none;
      color: inherit;
    }
  }
}

@media (max-width: 400px) {
  .HomeMain {
    padding-top: 30px !important;
  }
  .HomeMainBlockContent {
    &Left {
      & h2 {
        font-size: 2em !important;
      }

      & h4 {
        font-size: 1.5em !important;
      }

      & ul > li > span {
        font-size: 1.1em !important;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .mainDonationCards {
    margin-top: 20px;
    display: grid;
    justify-content: space-between;
    grid-template: none;
    grid-template-columns: repeat(1, minmax(100px, 1fr));
    gap: 20px;

    & > a {
      text-decoration: none;
      color: inherit;
    }
  }
}

.Main_Error {
  display: block;
  font-size: 20px;
  line-height: 130%;
  margin-top: 70px;
}

.BloodType_Text {
  letter-spacing: 0.25px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-top: 2px;
  color: $BaG_gray_one;
}
